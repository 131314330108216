<template>
  <!-- <div class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white">
    <div class="flex justify-between">
      <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{task.title}}</p>

      <img
        class="w-6 h-6 rounded-full ml-3"
        src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
        alt="Avatar"
      >
    </div>
    <div class="flex mt-4 justify-between items-center">
      <span class="text-sm text-gray-600">{{task.date}}</span>
      <badge v-if="task.type" :color="badgeColor">{{task.type}}</badge>
    </div>
  </div> -->
   <div class="content-row board col-12" :class="[task.result ? `status-${task.result}` : '']" v-show="task">
      <pre v-show="false">{{task}}</pre>
      <!-- Lead name -->
      <router-link :to="`/pipelines/${$route.params.id}/deals/${task.id}`" :class="[viewType === 'list' ? 'col-12 col-sm-3 lead-name' : 'col-12 lead-name']">
        <!-- <code>{{length}}</code> -->
        <!-- <code>{{task.id}}</code> -->
        <!-- <code class="w-100">Position: {{task.position}}</code> -->
        <!-- <code class="w-100">Index: {{index}}</code> -->
        <!-- <code class="w-100">Column_id: {{task.column_id}}</code> -->
        <!-- <code class="w-100">{{task.due_date}}</code> -->
        <span>{{task.title}}</span>
        <div v-if="viewType === 'list'" class="lead-infos">
          <p class="mb-0"><span class="material-symbols-outlined"> comment </span> 8</p>
          <p class="mb-0"><span class="material-symbols-outlined"> attach_file </span> 0</p>
        </div>
        <div class="description-lead" v-if="viewType === 'grid' && false">
          <p>{{task.description.substring(0, 35)}}{{task.description.length > 34 ? '...' : ''}}</p>
        </div>
      </router-link>
      <!-- Responsável -->
      <ul :class="[
        viewType === 'list' ? 'col-12 col-sm-2 owner-col' : 'col-12 owner-item', 'deal_offering_values'
      ]" v-if="false">
        <li>{{task.offering_values.single | currency}}</li>
        <li>{{task.offering_values.recurrent | currency}}</li>
      </ul>
      <div :class="[viewType === 'list' ? 'col-12 col-sm-2 owner-col' : 'col-12 owner-item']" v-if="false">
        <!-- <img src="@/assets/w-1.png" alt="Woman 1" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset=""> -->
        <template v-if="task.user">
          <!-- <div class="profile" v-for="(user, index) in task.users" :key="`${index}:${task.id}`" :style="{ backgroundColor: $strToColor(user.name) }">
            {{user.name | initials}}
          </div> -->
          <div class="profile">{{task.user.name | initials}}</div>
          <pre v-show="false">{{task.user}}</pre>
        </template>
      </div>
      <div :class="[viewType === 'list' ? 'col-12 col-sm-2 owner-col' : 'col-12 owner-item']">
        <div class="d-flex justify-content-between w-100 task-footer">
          <div class="task-extra-item d-flex align-items-center">
            <span class="material-icons-outlined money_icon">paid</span>
            <!-- span>{{(task.offering_values.single + task.offering_values.recurrent) | currency}}</span -->
            <b-button :id="`tasks_values_${task.id}`" class="tasks_total_values">
              {{(task.offering_values.single + task.offering_values.recurrent) | currency}}
            </b-button>
            <b-tooltip :target="`tasks_values_${task.id}`" placement="bottom">
              <div class="tasks_values_currency">
                <p>Únicos: {{task.offering_values.single | currency}}</p>
                <p>Recorrentes: {{task.offering_values.recurrent | currency}}</p>
              </div>
            </b-tooltip>
          </div>
          <div class="ml-auto task-extra-item d-flex align-items-center">
            <span class="material-symbols-outlined task-icon">comment</span>
            <span>{{task.comments_count}}</span>
            <div class="d-flex ml-1">
              <span class="material-symbols-outlined task-icon">shopping_cart</span>
              <span>+{{task.offerings.length}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Dt vencimento -->
      <div v-if="viewType === 'list'" :class="[viewType === 'list' ? 'col-12 col-sm-2' : 'col-12 dt-item']">
        <p class="dt-expire">
          <span class="material-symbols-outlined">calendar_today</span>
          {{task.date}}
        </p>
      </div>
      <!-- Canal comunicação -->
      <!--  <div v-if="task.ch_com" :class="[viewType === 'list' ? 'col-12 col-sm var-col' : 'col-12 var-col']">
          <template v-for="(channels, i) in task.ch_com">
              <span class="var" :key="i">{{channels.text}}</span>
          </template>
      </div> -->
      <div v-if="viewType === 'list'" :class="[viewType === 'list' ? 'col-12 col-sm var-col' : 'var-col']">
        <span class="material-symbols-outlined task-icon">shopping_cart</span>
      </div>
      <!-- Valor -->
      <div
        v-if="viewType === 'list'"
        :class="[viewType === 'list' ? 'col-12 col-sm-1 value-col' : 'value-item']"
      >
        <span class="material-symbols-outlined task-icon mr-1">monetization_on</span>
        <span v-if="task.value">{{task.value}}</span>
      </div>
      <div v-if="viewType === 'grid'" class="value-ch" v-show="false">
        <div class="ch-wrapper">
          <span :id="`popover-${task.id}`" class="material-symbols-outlined task-icon">shopping_cart</span>
          <span :id="`amount-${task.id}`" class="quantity-prod">+3</span>
          <b-popover :target="`popover-${task.id}`" triggers="hover" placement="bottomright" custom-class="ch-tooltip">
            <template v-for="(item, i) in 3">
              <span class="var" :key="i">Prod#{{i}}</span>
            </template>
          </b-popover>
        </div>
        <div v-if="task.value" class="value-wrapper">
          <span class="material-symbols-outlined task-icon mr-1"> monetization_on </span>
          <span>{{task.value}}</span>
        </div>
      </div>
      <div :class="[viewType === 'list' ? 'col-12 col-sm priority-col' : 'priority-item']">
        <div class="d-flex justify-content-between mt-2 priority-header">
          <div class="">
            <span v-if="task.priority" :class="`badge badge-${task.priority}`">
              ● {{$t(`workflow.workspace.status.${task.priority}`)}}
            </span>
          </div>
          <div class="contat-wrapper" v-if="task.user || task.contact">
            <template v-if="task.user">
              <!-- <div class="profile" v-for="(user, index) in task.users" :key="`${index}:${task.id}`" :style="{ backgroundColor: $strToColor(user.name) }">
                {{user.name | initials}}
              </div> -->
              <div class="profile">{{task.user.name | initials}}</div>
              <pre v-show="false">{{task.user}}</pre>
            </template>
            <template v-if="task.contact">
              <!-- <div class="profile" v-for="(user, index) in task.users" :key="`${index}:${task.id}`" :style="{ backgroundColor: $strToColor(user.name) }">
                {{user.name | initials}}
              </div> -->
              <div class="profile">{{task.contact.name ? task.contact.name : task.contact.first_name | initials}}</div>
              <pre v-show="false">{{task.contact}}</pre>
            </template>
          </div>
          <div>
            <b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <span class="material-symbols-outlined config_dot">more_horiz</span>
                <span class="sr-only">Search</span>
              </template>
              <b-dd-item href="#" @click="sendDetails(task)">
                <span class="material-symbols-outlined">edit</span>
                {{$t('app.edit')}}
              </b-dd-item>
              <b-dd-item href="#" @click="dupe(task.id, task.title)">
                <span class="material-symbols-outlined">control_point_duplicate</span>
                {{$t('generic-str.duplicate')}}
              </b-dd-item>
              <!--
                <b-dd-item href="#">
                  <span class="material-symbols-outlined">archive</span> Arquivar
                </b-dd-item>
                <b-dd-item href="#">
                  <router-link :to="`/workflow/workspace/board/detail/`+ task.id">
                    <span class="material-symbols-outlined">info</span>
                    Mais detalhes
                  </router-link>
                </b-dd-item>
              -->
            </b-dropdown>
          </div>
        </div>
        <!-- <span v-if="task.priority" :class="`badge badge-${task.priority}`">● {{$t(`workflow.workspace.status.${task.priority}`)}}</span>
        <ul class="list-unstyled options-content ml-auto">
          <li>
            teste
          </li>
          <li>
            <b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <span class="material-symbols-outlined config_dot">more_horiz</span>
                <span class="sr-only">Search</span>
              </template>
              <b-dd-item href="#" @click="sendDetails(task)">
                <span class="material-symbols-outlined">edit</span>
                {{$t('app.edit')}}
              </b-dd-item>
              <b-dd-item href="#" @click="dupe(task.id, task.title)">
                <span class="material-symbols-outlined">control_point_duplicate</span>
                {{$t('generic-str.duplicate')}}
              </b-dd-item>
                <! -- <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>Arquivar</b-dd-item> -- >
                <! -- <b-dd-item href="#"><router-link :to="`/workflow/workspace/board/detail/`+ task.id">
                  <span class="material-symbols-outlined"> info </span>Mais detalhes</router-link></b-dd-item> -- >
            </b-dropdown>
          </li>
        </ul> -->
      </div>
    </div>
</template>
<script>
import axios from '@/plugins/axios';
// import Badge from "./Badge.vue";
import Swal from 'sweetalert2';

const webApi = axios();

export default {
  /* components: {
    Badge
  }, */
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    viewType: {
      type: String,
    },
    index: {
      type: Number,
    },
    length: {
      type: Number,
    },
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
  },
  /* computed: {
    badgeColor() {
      const mappings = {
        Design: "purple",
        "Feature Request": "badge",
        Backend: "blue",
        QA: "green",
        default: "badge"
      };
      return mappings[this.task.type] || mappings.default;
    }
  } */
  methods: {
    sendDetails(e) {
      this.$root.$emit('getTaskDetails', e);
    },
    dupe(id, title) {
      Swal.fire({
        title: `${this.$t('generic-str.duplicate')} ${title}?`,
        text:
          this.$t('workflow.workspace.card.duplicate.description'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.duplicate'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('My ID: ', id);
          webApi.post(`/pipelines/deals/${id}/duplicate`, { position: this.length }).then(
            (response) => {
              this.$emit('updateFetch');
              console.log('Task: response: ', response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('workflow.workspace.card.edit.success'),
                type: 'success',
              });
            },
            (error) => {
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.deal_offering_values {
  left: 1rem;
}
.status-canceled {
  // box-shadow: 0px 3px 6px 0px #FF000078 !important;
  border-color: #FF000078 !important;
  border-width: 2px !important;
}
.status-done {
  // box-shadow: 0px 3px 6px 0px #FF000078 !important;
  border-color: #6fda2c78 !important;
  border-width: 2px !important;
}
.task-extra-item {
  gap: 5px;
}
.task-footer {
  font-size: .72rem;
  padding: .5rem 0;
}
.value-col {
  color: var(--gray-font-color-5);
  padding: 0;
}
.var-col {
  // justify-content: center;
  max-width: 20%;
  overflow-x: auto;
  gap: 5px;
  .var {
    padding: 4px 8px;
    background-color: #807C8D14;
    color: var(--gray-font-color-5);
    border-radius: 8px;
    font-size: .725rem;
  }
}
.task-list {
  .var-col {
    padding: 0;
  }
}

.priority-header {
  width: 100%;

  &::v-deep {
    .b-dropdown.btn-group {
      width: 45px !important;

      & > .btn, & > a:link.btn {
        height: 24px;
      }
    }
  }
}

.task-grid {
  .material-symbols-outlined.task-icon {
    font-size: 1rem;
    color: var(--gray-font-color-5);
  }
  .priority-item {
    order: -1;
    min-height: 40px !important;
  }
  .lead-name {
    padding: .5rem 0;
    order: 0;
    flex-wrap: wrap;
    cursor: pointer;
    // pointer-events: visible;
    span {
      font-weight: bold;
      font-size: .875rem;
      color: var(--foreground);
    }
  }
  .description-lead {
    width: 100%;
    p {
      color: var(--gray-font-color-5);
      margin-top: .5rem;
      margin-bottom: 0;
      font-size: .75rem;
    }
  }
  .dt-item {
    order: 1;
    min-height: auto;
    margin-bottom: .62rem;
  }
  .value-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    span {
      font-size: .875rem;
    }
  }
  .value-item {
    padding: 0;
    order: 2;
    min-height: auto;
    margin-bottom: 0;
    width: fit-content;
    min-height: 40px !important;
  }
  .var-col {
    order: 3;
    max-width: 100%;
    overflow-x: unset;
    min-height: auto;
    margin-bottom: 1.5rem;
    width: fit-content;
  }
  .owner-item {
    order: 4;
    padding: 0;
    img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }
    .money_icon {
      font-size: 1rem;
    }
  }
  .ch-wrapper {
   display: flex;
   align-items: center;
  }
  .quantity-prod {
    font-size: .875rem;
  }
  .value-ch {
    min-height: 40px !important;
  }
  .add-card-wrapper {
    order: 5;
  }
  .dt-expire, .value-item {
    color: var(--gray-font-color-5);
  }
  .options-content {
    margin-bottom: 0;
  }

  .contat-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 100%;
    justify-content: flex-end;
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: .72rem;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 4px var(--background-2);
    pointer-events: none;
    color: #fff;
    background-color: var(--clr-yup-purple) !important;

    svg {
      width: 20px;
      height: 20px;
    }

    &.faded-icon {
      background-color: var(--c-grey-100);
      color: var(--c-grey-400);
    }

    &.filled-icon {
      background-color: var(--c-blue-500);
      color: #fff;
    }
  }
}

.tasks_total_values {
  padding: 0;
  height: auto;

  &, &:hover {
    border: none !important;
    background: transparent !important;
    color: inherit !important;
    font-size: inherit !important;
  }
}

.tasks_values_currency {
  p {
    margin-bottom: 0;
  }
}

.tasks_values {
  float: right;
  position: relative;
  // right: -12% !important;
  padding-top: 10px;

  &::v-deep {
    button, button:hover, button:focus, button:active {
      background: transparent !important;
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      color: inherit !important;
      box-shadow: none !important;
      height: auto !important;
      font-size: .82rem;
    }
  }
}
</style>
